@import '../../styles/theme-settings.scss';

.profile-body {
  padding: 20px;
  background-color: var(--base-tertiary-color);
  flex: auto;
  .csv-file {
    visibility: hidden;
  }
  .name-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
  }
  .profile-head {
    @include font-source(22px, var(--text-black), 600, 26px);
    margin-bottom: 5px;
  }
  .profile-subhead {
    @include font-source(16px, var(--text-secondary-color), 400, 1);
  }
  .table-name-head {
    @include font-source(12px, var(--text-quaternary-color), 400, 1);
    letter-spacing: 5%;
  }
  .user-name-col {
    @include font-source(16px, var(--text-black), 600, 20px);
    margin-top: 5px;
  }
  .table-header-name {
    @include font-source(16px, var(--text-black), 600, 20px);
  }
  .profile-images .ant-image,
  .action-icon {
    margin: 0px 10px;
  }
  .profile-body {
    display: flex;
    flex-direction: column;
  }
  .table-section {
    margin: 26px 0px;
    flex: auto;
    background: var(--base-background);
  }
  .table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px;
  }
  .sort-filter {
    display: flex;
    @include font-source(16px, var(--base-primary-color), 600, 20px);
    width: 100px;
    justify-content: space-between;
    align-items: center;
  }
  .delete-btn {
    width: 150px;
    margin: 5px;
    border-radius: 5px;
  }
  .header-section {
    display: flex;
    justify-content: space-between;
  }
  .report-btn {
    width: 97px;
    height: 45px;
    color: #222460;
    background: #fff;
    border-radius: 4px;
    border-color: #fff;
  }
  .report-btn.active-btn {
    background: #222460;
    color: #fff;
  }
}

.abuse-details {
  padding: 30px;
  .back-arrow {
    font-size: 34px;
    margin-right: 20px;
    cursor: pointer;
  }
  .card-title {
    color: #000;
    font-size: 18px;
  }
  .profile-head {
    @include font-source(22px, var(--text-quinary-color), 600, 26px);
    margin-bottom: 5px;
  }
  .profile-subhead {
    @include font-source(16px, var(--text-secondary-color), 400, 1);
  }
  .abuse-btn {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    width: 170px;
    margin: 0px 10px;
  }
  .deact-btn {
    background: transparent;
    border-radius: 6px;
    border-color: #222460;
    color: #222460;
  }
  .delete-btn {
    background: #c60000;
    border-radius: 6px;
    border-color: #c60000;
    color: #fff;
  }
  .message-btn {
    background: #222460;
    border-radius: 6px;
    border-color: #222460;
    color: #fff;
  }
  .abused-card {
    width: 100% !important;
    margin-top: 50px;
    .ant-card-body {
      background: rgba(34, 36, 96, 0.1);
    }
  }
  .abused-name-section {
    display: flex;
    margin-left: 35px;
    flex-direction: column;
  }
  .abused-profile {
    display: flex;
    margin-bottom: 15px;
    .title {
      @include font-source(16px, var(--text-secondary-color), 400, 1);
      width: 145px;
    }
    .title-value {
      @include font-source(16px, var(--text-black), 400, 1);
    }
  }
}
.block_detail .ant-table {
  height: calc(100vh - 450px) !important;
}
