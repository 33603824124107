@import '../../styles/theme-settings.scss';

.profile-body {
  padding: 20px;
  background-color: var(--base-tertiary-color);
  flex: auto;
  .sort-checkbox {
    display: flex;
    justify-content: space-between;
  }
  .profile-head {
    @include font-source(22px, var(--text-black), 600, 26px);
    margin-bottom: 5px;
  }
  .profile-subhead {
    @include font-source(16px, var(--text-secondary-color), 400, 1);
  }
  .header-div {
    display: flex;
    justify-content: space-between;
  }
  .send-btn {
    height: 45px;
    color: #fff;
    background: #222460;
    border-radius: 4px;
    border-color: #222460;
  }

  .profile-body {
    display: flex;
    flex-direction: column;
  }
  .message_type {
    margin-top: 30px;
    margin-bottom: 5px;
    margin-left: 20px;
    .type_label {
      margin-right: 10px;
      color: #000000;
      font-weight: 400;
    }
  }
  .message-row {
    .ant-col {
      margin-bottom: 10px;
      width: 50%;
    }
    .ant-card {
      height: 100%;
      margin: 5px 20px;
    }
    .user-card {
      .ant-card-head {
        background: #017922;
        color: #fff;
      }
      .ant-checkbox-wrapper {
        margin-top: 20px;
      }
    }
    .user-card,
    .type-card {
      .ant-card-head {
        background: #017922;
        color: #fff;
      }
      .ant-checkbox-group {
        display: flex;
        flex-direction: column;

        .ant-checkbox-group-item {
          margin-top: 15px;
        }
      }
    }

    .message-card {
      .ant-card-head {
        background: #545454;
        color: #fff;
      }
    }
  }
  .sort-filter {
    display: flex;
    @include font-source(16px, var(--base-primary-color), 600, 20px);
    width: 190px;
    justify-content: space-between;
    align-items: center;
  }
}
