@import '../../styles/theme-settings.scss';

.announce-home {
  .table-section {
    margin: 26px 0px;
    flex: auto;
    background: var(--base-background);
    min-height: calc(100vh - 250px);
    .header-name {
      font-size: 18px;
      font-weight: 600;
    }
  }
  .table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px;
  }
  .pagination {
    display: flex;
    justify-content: end;
  }
  .sort-filter {
    display: flex;
    @include font-source(16px, var(--base-primary-color), 600, 20px);
    width: 80px;
    justify-content: space-between;
    align-items: center;
  }
  .card-wrap {
    display: flex;
    padding: 30px 20px;
    border-bottom: 1px solid #9b9b9b;
    width: 100%;
    .image {
      width: 250px;
      img {
        background-color: #222460;
        border-radius: 50%;
        padding: 20px;
      }
    }
    .contents {
      display: flex;
      flex-direction: column;
      // width: 78%;
      width: 100%;
      color: #000000;
      label {
        color: #9b9b9b;
        padding-bottom: 5px;
      }
      padding-right: 20px;
      .content-head {
        display: flex;
        justify-content: space-between;
        padding-bottom: 10px;
        align-items: center;
        width: 100%;
      }
      .content-title {
        padding-bottom: 10px;
        .ql-toolbar {
          display: none;
        }
        .ql-container.ql-snow {
          border: 0px;
        }
      }
    }
  }
  .send-btn {
    height: 40px;
    color: #fff;
    background: #222460;
    border-radius: 4px;
    border-color: #222460;
    &:focus {
      background: #222460;
    }
  }
}
.page_scroll {
  background-color: #f0f2f5;
  height: calc(100vh - 100px);
  overflow-y: scroll;
  padding-right: 10px;
}
.msg_wrap {
  margin-bottom: 25px;
  .ql-editor {
    min-height: 200px;
  }
}
