@import '../styles/variables.scss';

:root {
  --base-background: #{$base-bg};
  --base-primary-color: #{$theme-primary-color};
  --base-secondary-color: #{$theme-secondary-color};
  --base-tertiary-color: #{$theme-tertiary-color};
  --base-quaternary-color: #{$theme-quaternary-color};
  --base-quinary-color: #{$theme-quinary-color};
  --base-primary2-color: #{$theme-primary2-color};
  --base-text: #{$base-text};
  --base-text-dark: #{$base-text-dark};
  --base-text-first: #{$base-text-first};
  --text-quaternary-color: #{$text-quaternary-color};
  --text-secondary-color: #{$text-secondary-color};
  --text-danger-color: #{$theme-danger-color};
  --text-black: #{$black};
  --text-quinary-color: #{$text-quinary-color};
}
[data-theme='dark'] {
  --base-background: #{$base-bg-dark};
  --base-primary-color: #{$theme-primary-color-dark};
  --base-secondary-color: #{$theme-secondary-color-dark};
  --base-tertiary-color: #{$theme-tertiary-color-dark};
  --base-quaternary-color: #{$theme-quaternary-color-dark};
  --base-quinary-color: #{$theme-quinary-color-dark};
  --base-primary2-color: #{$theme-primary2-color-dark};
  --base-text: #{$base-text-dark};
  --text-quaternary-color: #{$text-quaternary-color-dark};
}
