@import '../../styles/theme-settings.scss';

.button-login {
  background: var(--base-primary-color);
  border-color: var(--base-primary-color);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  width: 100%;
  height: 60px;
  font-weight: 500;
  font-size: 21px;
  line-height: 28px;
  color: #ffffff;
}
.login-image {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
}
.button-login:active,
.button-login:hover {
  background: var(--base-primary-color);
  border-color: var(--base-primary-color);
}
.login-input {
  width: 100%;
  height: 60px;
  background: #ffffff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  font-weight: 500;
  font-size: 19px;
  line-height: 28px;
  color: #000000;
}
.login-form .ant-form-item {
  margin-bottom: 30px;
}

.footlight-logo {
  margin-bottom: 25px;
}
.footlight-text-logo {
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;
  color: #1b1b1b;
}
.login-center-text {
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: #676767;
  margin-bottom: 10px;
}
.login-center-text.sign-in-text {
  color: #031d45;
}
.login-label {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  margin-left: 5px;
  color: #000000;
  margin-bottom: 5px;
}

.ant-layout-sider.login-sider {
  flex: 0 0 400px !important;
  max-width: 400px !important;
  width: 400px !important;
  text-align: center;
  height: 100%;
}
.login-root {
  min-height: calc(100vh);
  background: var(--base-primary-color);
}
.login-root .ant-layout {
  background: #ffffff;
}
.ant-layout.login-input-root {
  // align-items: center;
  background: #fff;
  // width: 650px;
  flex: unset;
  justify-content: center;
  padding: 35px;
  border-radius: 6px;
}
.forgot-create-div {
  display: flex;
  justify-content: space-between;
  color: #e96f0a;
  font-weight: 600;
}
.login-div {
  width: 550px;
}
.login-logo {
  width: 370px;
  margin-top: 140px;
}
.ant-col.login-input-col {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}
.ant-row.login-input-row {
  width: 100%;
  height: 100vh;
}
.login-root .spin {
  right: 50%;
}
.login-root .ant-input[disabled] {
  color: black;
}
.login-frame-icon {
  display: flex;
  justify-content: flex-end;
  margin-right: -33px;
  margin-top: 25px;
}
.anounce_title {
  width: 100%;
  margin-bottom: 15px;
}
