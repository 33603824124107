@import '../../styles/theme-settings.scss';

.dashboard-home {
  .ant-table {
    height: auto;
    .ant-table-thead {
      tr,
      th {
        background-color: rgba(34, 36, 96, 0.3);
      }
    }
  }
  .main_head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding: 15px 0px 0px 0px;
    h2 {
      color: #000000;
    }
    h3 {
      color: #000000;
      font-size: 20px;
      font-weight: 600;
    }
  }
  .desc {
    color: #545454;
    padding-bottom: 10px;
  }
  .border-line {
    margin: 0px 1px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  }
  .kpiwrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 20px;
    .dashbrd_card {
      width: 200px;
      height: 114px;
      background: #ffffff;
      border-left: 4px solid #2f3285;
      margin-left: 15px;
      padding-left: 20px;
      margin-right: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      // align-items: center;
      .count {
        font-weight: 600;
        font-size: 28px;
        color: #000000;
      }
      .label {
        font-weight: 400;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.5);
        padding-left: 5px;
      }
    }
  }
  .sub_head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    h2 {
      color: #000000;
      font-weight: 600;
      font-size: 16px;
      line-height: 150%;
    }
  }
  .dash-table {
    margin: 10px 0px;
    h3 {
      color: #000000;
      margin: 10px 0px;
    }
    .view_more {
      text-decoration: underline;
      margin: 10px 0px 15px 0px;
      cursor: pointer;
      color: #222460;
    }
  }
  .graph_wrap {
    background-color: #ffffff;
    margin: 10px 0px;
    padding: 5px 10px;
    .graph_title {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .period_dropdown {
    background-color: #222460;
    color: #ffffff;
    padding: 5px 3px;
    border: 1px solid #222460;
    border-radius: 5px;
  }
  .active-btn {
    background-color: #222460;
    border: 1px solid #222460;
    color: #ffffff;
    &:focus {
      background-color: #222460;
      border: 1px solid #222460;
      color: #ffffff;
    }
  }
}
.page_scroll {
  background-color: #f0f2f5;
  height: calc(100vh - 100px);
  overflow-y: scroll;
  padding-right: 10px;
}
