@import '../../styles/theme-settings.scss';

.dashboard-layout-home {
  min-height: 100vh;
  height: 100vh;

  // @include font-source(28px, #84a60f, 400, 1);

  .dashboard-sider {
    flex: 0 0 275px !important;
    max-width: 275px !important;
    background-color: var(--base-primary-color);
    .app-logo {
      @include flexboxCentre();
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      .app-logo-img {
        margin: 10px 0px;
      }
    }
    .ant-layout-sider-children {
      background-color: var(--base-primary-color);
    }
    .ant-layout-sider,
    .ant-menu {
      padding: 15px;
      background-color: var(--base-primary-color);
      height: unset !important;
      .ant-menu-item:after {
        border-right: unset;
      }
      .ant-menu-item-selected {
        background: rgba(255, 255, 255, 0.15);
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
        border-radius: 6px;
      }
      .menu-logo {
        margin-right: 20px;
      }
      .side-menu-div a {
        @include font-source(16px, var(--base-background), 600, 1);
      }
    }
  }
}
