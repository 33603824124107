@import '../../styles/theme-settings.scss';

.profile-body {
  padding: 20px;
  background-color: var(--base-tertiary-color);
  flex: auto;

  .name-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
  }
  .profile-head {
    @include font-source(22px, var(--text-black), 600, 26px);
    margin-bottom: 5px;
  }
  .profile-subhead {
    @include font-source(16px, var(--text-secondary-color), 400, 1);
  }
  .table-name-head {
    @include font-source(12px, var(--text-quaternary-color), 400, 1);
    letter-spacing: 5%;
  }
  .user-name-col {
    @include font-source(16px, var(--text-black), 600, 20px);
    margin-top: 5px;
  }
  .table-header-name {
    @include font-source(16px, var(--text-black), 600, 20px);
  }
  .profile-images .ant-image,
  .action-icon {
    margin: 0px 10px;
  }
  .profile-body {
    display: flex;
    flex-direction: column;
  }
  .table-section {
    margin: 26px 0px;
    flex: auto;
    background: var(--base-background);
  }
  .table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px;
  }
  .sort-filter {
    display: flex;
    @include font-source(16px, var(--base-primary-color), 600, 20px);
    width: 100px;
    justify-content: space-between;
    align-items: center;
  }
  .delete-btn {
    width: 150px;
    margin: 5px;
    border-radius: 5px;
  }
  .header-section {
    display: flex;
    justify-content: space-between;
  }
  .report-btn {
    width: 97px;
    height: 45px;
    color: #222460;
    background: #fff;
    border-radius: 4px;
    border-color: #fff;
  }
  .report-btn.active-btn {
    background: #222460;
    color: #fff;
  }
  .add-faq-btn {
    color: #222460;
    font-size: 17px;
    font-weight: 600;
    margin-right: 10px;
    .span-content {
      text-decoration: underline;
    }
  }
  .report_head {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    color: #000;
  }
  .report_heads {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    color: #000;
    padding: 0px 10px;
    border: 1px solid #d9d9d9;
    background-color: #fafafa;
    .heading {
      display: flex;
      align-items: center;
      font-size: 18px;
      color: #000;
      line-height: 1.5;
      padding: 10px 0px;
    }
    .expand_icon {
      padding: 0px 5px;
      cursor: pointer;
    }
  }
  .report_content {
    padding: 0px 10px;
    // border-right: 1px solid #d9d9d9;
    // border-left: 1px solid #d9d9d9;
  }
  .content_action {
    padding-bottom: 10px;
    .edit_btn,
    .edit_btn:hover {
      border: 1px solid #222460 !important;
      color: #222460 !important;
    }
  }
}
.ql-editor.ql-blank {
  min-height: 250px;
}
.add-modal {
  .form-item-button .ant-form-item-control-input-content {
    justify-content: center;
    align-items: center;
    display: flex;
    .button-login {
      height: 50px;
      width: unset;
    }
  }

  .ant-modal-title {
    font-size: 18px;
    font-weight: 600;
  }
}

.swipe-content {
  height: calc(100vh - 235px);
  overflow: auto;
}
.drag-save {
  margin-top: 10px;
  display: flex;
  justify-content: end;
}
