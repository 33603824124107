@import '../../../styles/theme-settings.scss';

.dashboard-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 90px;
  padding: 15px;
  background-color: var(--base-secondary-color);

  .page-header {
    @include font-source(22px, var(--base-text-dark), 600, 22);
  }
  .logout-btn {
    background: #242960;
    border-color: #242960;
    color: #fff;
  }
  .opacity-0 {
    opacity: 0;
  }
  .opacity-1 {
    opacity: 1;
  }
}
