@import '../../styles/theme-settings.scss';

.view-profile {
  display: flex;
  .title-value {
    margin-left: 20px;
  }
}
.profile-body {
  padding: 20px;
  background-color: var(--base-tertiary-color);
  flex: auto;

  .name-col {
    display: flex;
    /* align-items: center; */
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
  }
  .profile-head {
    @include font-source(22px, var(--text-black), 600, 26px);
    margin-bottom: 5px;
  }
  .profile-subhead {
    @include font-source(16px, var(--text-secondary-color), 400, 1);
  }
  .table-name-head {
    @include font-source(12px, var(--text-quaternary-color), 400, 1);
    letter-spacing: 5%;
  }
  .user-name-col {
    @include font-source(16px, var(--text-black), 600, 20px);
    margin-top: 5px;
  }
  .table-header-name {
    @include font-source(16px, var(--text-black), 600, 20px);
  }
  .profile-images .ant-image,
  .action-icon {
    margin: 0px 10px;
  }
  .profile-body {
    display: flex;
    flex-direction: column;
  }
  .table-section {
    margin: 26px 0px;
    flex: auto;
    background: var(--base-background);
  }
  .table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px;
  }
  .sort-filter {
    display: flex;
    @include font-source(16px, var(--base-primary-color), 600, 20px);
    width: 190px;
    justify-content: space-between;
    align-items: center;
  }
}
