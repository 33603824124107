@import '../../styles/theme-settings.scss';

.sort-filter {
  display: flex;
  @include font-source(16px, var(--base-primary-color), 600, 20px);
  width: 85px;
  justify-content: space-between;
  align-items: center;
}
.csv-file {
  visibility: hidden;
}
.show-otp {
  border: 1px solid #060448;
  border-radius: 5px;
  padding: 8px;
  margin: 0px 10px;
}
.details-head {
  display: flex;
  .back-arrow {
    font-size: 26px;
    margin-top: 3px;
    margin-right: 12px;
  }
}
.user-management-table .ant-table-pagination.ant-pagination {
  margin-top: 10px;
}

.dashboard-layout-home .ant-table {
  height: calc(100vh - 305px);
  overflow: auto;
}
.wrapper {
  margin: 15px 20px;
  .content_area {
    background-color: #ffffff;
    padding: 15px;
    border-radius: 8px;
    .header_sec {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // margin-top: 35px;
      .filters {
        display: flex;
        .sort {
          margin: 0px 10px;
        }
      }
    }
    .user_item {
      display: flex;
      flex-direction: column;
      label {
        color: #9b9b9b;
        font-size: 13px;
      }
      .value {
        color: #000000;
        font-weight: 600;
        font-size: 14px;
      }
    }
    .img_sec {
      width: 62px;
      height: 82px;
    }
  }
  .main_head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding: 15px 0px 0px 0px;
    h2 {
      color: #000000;
    }
    h3 {
      color: #000000;
      font-size: 20px;
      font-weight: 600;
    }
  }
  .desc {
    color: #545454;
    padding-bottom: 10px;
  }
  .card {
    box-shadow: #9b9b9b;
    background-color: #ffffff;
    width: 100%;
    margin-top: 15px;
    .header {
      padding: 10px;
    }
    .content {
      background-color: rgba(34, 36, 96, 0.1);
      padding: 10px;
      display: flex;
      width: 100%;
      .img_sec {
        width: 20%;
      }
      .details {
        width: 100%;
        .field_list {
          display: flex;
          width: 100%;
          padding-bottom: 8px;
          label {
            color: #9b9b9b;
            width: 20%;
          }
          .value {
            width: 80%;
          }
        }
      }
      .act_details {
        display: flex;
        flex-wrap: wrap;

        .activity_list {
          display: flex;
          width: 50%;
          padding-bottom: 8px;
          flex-wrap: wrap;
          padding-left: 5px;
          label {
            color: #9b9b9b;
            width: 40%;
          }
          .value {
            width: 40%;
          }
        }
      }
    }
  }
  .user_accord {
    margin-top: 10px;
    .ant-space {
      width: 100%;
      .ant-collapse {
        border: none;
        .ant-collapse-header-text {
          font-weight: 600;
          color: #000000;
          font-size: 20px;
          width: 100%;
        }
      }
      .ant-collapse-header {
        background-color: #ffffff;
      }
      .ant-collapse-content {
        border: none;
        background-color: rgba(34, 36, 96, 0.1);
      }
    }
    .act_details {
      display: flex;
      flex-wrap: wrap;

      .activity_list {
        display: flex;
        width: 50%;
        padding-bottom: 8px;
        flex-wrap: wrap;
        padding-left: 5px;
        label {
          color: #9b9b9b;
          width: 25%;
        }
        .value {
          width: 25%;
        }
      }
    }
    .report_head {
      display: flex;
      width: 100%;
      :nth-child(1) {
        width: 50%;
        text-align: center;
      }
      :nth-child(2) {
        width: 50%;
        text-align: center;
      }
    }
    .report_list {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      .report_content {
        width: 50%;
        text-align: center;
        padding: 10px 0px;
      }
    }
  }
  .action_btns {
    display: flex;
    justify-content: center;
    margin: 15px 0px;
    padding-bottom: 20px;
    :nth-child(1) {
      margin-right: 10px;
    }
  }
}
.secondary-btn {
  background-color: #222460 !important;
  border-color: #222460 !important;
  border-radius: 6px !important;
}
.page_scroll {
  height: calc(100vh - 100px);
}

.userSearchBox {
  margin-bottom: 1rem;
  label {
    margin-right: 10px;
  }
  input {
    margin-right: 1rem;
  }
}
